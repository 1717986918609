import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import isEqual from 'lodash/isEqual';

import { resetOverlay, showUserModal } from 'Actions/UiActions';
import { trackGAEvent } from 'Actions/adsActions';

import SvgIcon from 'Components/svg/SvgIcon';
import DateComponent from 'Components/common/dateComponent';

import { parsePath, pathToUserProfile } from 'Utils/nav/NavHelpers';
import fixUrlProtocol from 'Utils/fixUrlProtocol';
import rawToReact from 'Utils/bbcode/RawToReact';
import decode from 'Utils/encoding/decode';

class UserIconComponent extends React.Component {

	shouldComponentUpdate( nextProps ) {
		// very rarely we'll update it
		return (
			!isEqual( nextProps.user, this.props.user ) ||
			!isEqual( nextProps.layout, this.props.layout ) ||
			nextProps.noAction !== this.props.noAction ||
			nextProps.className !== this.props.className
		);
	}

	_showUserModal = ( e, path, user ) => {
		e.stopPropagation();

		this.props.dispatch( resetOverlay() );
		this.props.dispatch( showUserModal( path, user ) );
		// Record the Google Analytics event.
		this.props.dispatch( trackGAEvent( 1, `userModal_${ path[1] }`, 'uiAction', 'tap' ) );
	};

	showUserProfile( e, user ) {
		e.stopPropagation();

		if( this.props.noClick === true ) {
			return;
		}

		this.props.dispatch( resetOverlay() );

		this.props.history.push( pathToUserProfile( user.id ) );
	}

	render() {
		const { user, defaultUrl, layout, noAction, className, skipElements } = this.props;

		let avoidAdsClass = 'no-ad nolinks nooptimize norewrite',
			onClick = () => {},
			userAvatar = fixUrlProtocol( user.avatar || defaultUrl ),
			styles = {
				backgroundImage: `url(${ userAvatar })`
			},
			onlineStatus = !skipElements.status && user.online === true ? <div className="status online">online</div> : null,
			userLocation = !skipElements.location && user.location ? <span className="userLocation">{ rawToReact( user.location ) }</span> : null;

		if( !noAction ) {
			let path = parsePath( this.props.location.pathname );
			onClick = (e)=> { this._showUserModal( e, path, user ) };
		}

		if( this.props.onClick ) {
			onClick = this.props.onClick;
		}

		let joinDate = null,
			userInfo = {
				mentions: !skipElements.mentions && user.mentions ? <span className="userPosts">Mentions: { user.mentions }</span> : null,
				rank: !skipElements.rank && user.rank ? <p className="userRank"><span>{ rawToReact( user.rank ) }</span></p> : null,
				specialRank: !skipElements.rank && user.specialRank ? <p className="userRank userSRank"><span>{ rawToReact( user.specialRank ) }</span></p> : null,
				posts: !skipElements.posts && user.postsCount ? <span className="userPosts">Posts: { user.postsCount }</span> : null,
				userTitle: !skipElements.userTitle && user.userTitle ? <span className="userTitle">{ rawToReact( user.userTitle ) }</span> : null,
				joinDate: !skipElements.joinDate && user.joinDate || null
			};

		switch( layout ) {
			case 'complete':
				joinDate = !( skipElements.userData || skipElements.joinDate ) && userInfo.joinDate ? <span className="userJoinDate">Join: <DateComponent timestamp={ userInfo.joinDate } format={ 'MMM YYYY' } /></span> : null;
				userInfo.joinDate = joinDate;

				const userName = !skipElements.userName ? <div className="username">{ decode( user.userName ) }</div> : null,
					userData = !skipElements.userData && ( Object.values( userInfo ).filter( i => i ).length || userLocation ) ?
					<React.Fragment>
						{ userInfo.specialRank }
						<p className="userData">
							{ userInfo.userTitle }
							{ userInfo.joinDate }
							{ userInfo.mentions }
							{ userInfo.posts }
							{ userLocation }
						</p>
						{ userInfo.rank }
					</React.Fragment> : null;

				return (
					<div className={`uiContainer ${ avoidAdsClass } ${ className ? className : '' }`} onClick={ onClick } >
						<div className="avatar" style={ styles } />
						{ userName }
						{ onlineStatus }
						{ userData }
					</div>
				);

			case 'quote':
				let avatar = user.avatar ? <div className="avatar" style={ styles } /> : <div className="avatar"><SvgIcon icon="account_circle" size="24"/></div>;
				return (
					<div className={`uiContainer ${ avoidAdsClass } ${ className ? className : '' }`} onClick={ onClick }>
						{ avatar }
					</div>
				);

			case 'userprofile':
				return (
					<div className={`uiContainer ${ avoidAdsClass } ${ className ? className : '' }`} onClick={ onClick }>
						<div className="avatar" style={ styles } />
						{ onlineStatus }
					</div>
				);

			case 'sidemenu':
				const decodedUserName = decode( user.userName );
				return (
					<li className={`current-user ${ avoidAdsClass } ${ className ? className : '' }`}  onClick={ e => this.showUserProfile( e, user ) }>
						<div className="avatar" style={ { overflow: 'hidden'} } >
							<img src={ userAvatar } alt={ decodedUserName } width={ 30 } height={ 30 } />
						</div>
						<h2 className="username" id="sideMenuUserName">{ decodedUserName }</h2>
					</li>
				);

			case 'editme':
				let canChangeAvatar = user.usercp && user.usercp.profile && user.usercp.profile.avatarLimits.permissions,
					editButton = canChangeAvatar ? <div className="update-me" onClick={ onClick }><SvgIcon icon="photo_camera" size={ this.props.size }/></div> : null;

				return (
					<div className={`uiContainer ${ avoidAdsClass } ${ className ? className : '' }`}>
						<div className="avatar" style={ styles } />
						{ editButton }
					</div>
				);

			default:
				throw new Error( 'Valid layout required for UserIconComponent' );
		}
	}
}

UserIconComponent.defaultProps = {
	defaultUrl: 'https://staticimages.topify.com/default_avatar.png',
	layout: 'complete',
	skipElements: {}, // we'll be able to skip elements to show on complete view
	className: '',
	user: {
		avatar: '',
		userName: 'Guest'
	}
};

UserIconComponent.propTypes = {
	user: PropTypes.object.isRequired,
	layout: PropTypes.string,
	dispatch: PropTypes.func.isRequired
};

export default connect()( withRouter( UserIconComponent ) );
