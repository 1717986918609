import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import isEqual from 'lodash/isEqual';

import RegistrationCall from 'Components/common/RegistrationCallComponent';

import rawToReact from 'Utils/bbcode/RawToReact';
import { pathToForum, pathToForumsIndex } from 'Utils/nav/NavHelpers';

import { resetInfinite } from 'Actions/InfiniteScrollActions'
import { resetForum } from 'Actions/ForumviewActions';

import breadcrumbSelector from 'Selectors/breadcrumbSelector';

class BreadcrumbComponent extends React.Component {
	constructor(props) {
		super(props);

		this.step = 15;
		this.scroll = 0;
		this.scrollTimer = null;
		this.breadCrumb = null;
	}

	componentDidMount() {
		this.scrollToLastElement();
	}

	componentWillUnmount() {
		cancelAnimationFrame( this.scrollTimer );
	}

	shouldComponentUpdate( nextProps ) {
		// very rarely we'll update it
		return !isEqual( nextProps.path, this.props.path );
	}

	componentDidUpdate() {
		this.scrollToLastElement();
	}

	scrollToLastElement = () => {
		// get last element and container
		let lastItem = document.querySelectorAll( '#breadCrumbBar li:last-of-type' );

		if( this.breadCrumb && lastItem && lastItem.length > 0 ) {
			this.scroll = parseInt( lastItem[ 0 ].getBoundingClientRect().left );

			this.scrollTimer = requestAnimationFrame( this.scrollTo );
		}
	};

	scrollTo = () => {
		if( this.scroll > 0 ) {
			this.scroll -= this.step;
			if( this.breadCrumb ) {
				this.breadCrumb.scrollLeft += this.step;
			}
			this.scrollTimer = requestAnimationFrame( this.scrollTo );
		} else {
			this.scroll = 0;
		}
	};

	gotoIndex = () => {
		this.props.dispatch( resetInfinite() );
		this.props.dispatch( resetForum() );
		this.props.history.push( pathToForumsIndex() );
	};

	gotoForum = ( id ) => {
		this.props.dispatch( resetInfinite() );
		this.props.dispatch( resetForum() );
		this.props.history.push( pathToForum( id ) );
	};

	render() {
		let { path, routeParams } = this.props;
		let registerNow = this.props.userId ? null : <RegistrationCall type="breadcrumb" />;

		path = path ? path : []; // prevents error when path is empty

		const reversedPath = (
			routeParams.forumId &&
			path.length > 0 &&
			parseInt( routeParams.forumId ) === parseInt( path[0].id ) ?
				[...path].splice(1) : [...path]
		).reverse(); // if we are in the subforum don't show it on the breadcrumb

		const pathComponents = reversedPath.map(
			(p, i) => <li key={ i } className="item" onClick={ () => this.gotoForum(p.id) } >{ rawToReact(p.title) }</li>
		);

		return (
			<div key="breadcrumb" id="breadCrumbBar" className="bcContainer nolinks" >
				<ul ref={ (bc) => this.breadCrumb = bc } >
					<li className="item" onClick={ this.gotoIndex }><span>Index</span></li>
					{ pathComponents }
				</ul>
				{ registerNow }
			</div>
		);
	}
}

BreadcrumbComponent.displayName = 'BreadcrumbComponent';
BreadcrumbComponent.defaultProps = {
	scrollOptions: {
		eventPassthrough: true,
		scrollX: true,
		scrollY: false,
		preventDefault: false
	}
};

BreadcrumbComponent.propTypes = {
	routeParams: PropTypes.object.isRequired
};

export default withRouter( connect( breadcrumbSelector )( BreadcrumbComponent ) );
