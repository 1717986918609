import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import queryString from 'query-string';

import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

import {
	checkAdsPermissions,
	checkGDPRStatus,
	preInitAds,
	initAdLibraries,
	onPageView,
	refreshTaboola
} from 'Actions/adsActions';
import {
	displayNotification,
	hideConfirmModal,
	hideLoginModal,
	hideReplyModal,
	hideUserModal,
	resetOverlay
} from 'Actions/UiActions';
import { clearFirstLoad } from 'Actions/GlobalMetaActions';
import { receiveCurrentUser } from 'Actions/UstateActions';
import { getLandingPage } from 'Actions/UserSettingsActions';
import { onlineStatus } from 'Actions/MetaActions';
import { retrievableContent } from 'Actions/InfiniteScrollActions';

import Routes from '../routes';

// loader components
import Header from 'Components/header/HeaderComponent';
import HeaderOverflow from 'Components/header/HeaderOverflowComponent';
import UserModal from 'Components/modals/UserModalComponent';

import AdComponent from 'Components/ads/AdComponent';
import TopifyAlertTemplate from 'Components/alerts/alertsTemplate';
import Notices from 'Components/alerts/NoticesListComponent';
import LoaderCss from 'Components/common/LoaderCssComponent';

import pageSelectors from 'Selectors/pageSelectors';

import { compareVersions, devicePlatform } from 'Utils/deviceType';
import { pathToForumsIndex } from 'Utils/nav/NavHelpers';

import Loadable from 'react-loadable';

// lazy loaded chunks
const LoadableSidebar = Loadable( {
	loader: () => import( /* webpackChunkName: "modals" */ 'Components/sidebar/SidebarComponent' ),
	loading: () => null,
} );
const LoadableSearchBar = Loadable( {
	loader: () => import( /* webpackChunkName: "modals" */ 'Components/header/SearchBarComponent' ),
	loading: () => null,
} );
const LoadableShareMenu = Loadable( {
	loader: () => import( /* webpackChunkName: "modals" */ 'Components/header/ShareMenuComponent' ),
	loading: () => null,
} );
const Overlay = Loadable( {
	loader: () => import( /* webpackChunkName: "modals" */ 'Components/common/overlayComponent' ),
	loading: () => null,
} );
const LoadableLoginModal = Loadable( {
	loader: () => import( /* webpackChunkName: "modals" */ 'Components/modals/LoginModalComponent' ),
	loading: () => null,
} );
const LoadableConfirmModal = Loadable( {
	loader: () => import( /* webpackChunkName: "modals" */ 'Components/modals/ConfirmModalComponent' ),
	loading: () => null,
} );
const LoadableReplyModal = Loadable( {
	loader: () => import( /* webpackChunkName: "replyModal" */ 'Components/modals/ReplyModalComponent' ),
	loading: () => null,
} );

class PageComponent extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
			loaded: false,
			modalOpen: null,
			overflowOpen: null,
			announcementsOpen: null,
			deferredPrompt: false,
			isOnline: navigator.onLine ? 'online' : 'offline'
		};

		this.isOldPhone = this.checkOldPhone();
		this.skipHideModals = false;
		this.initialMsg = {};
		this.deferredPrompt = null;
	}

	static getDerivedStateFromProps( props, state ) {
		let newState = {},
			anyOverflowOpen = Object.values( props.ui.overflows ).some( v => v ), // overflow elements
			anyModalOpen = Object.values( props.ui.modals ).some( v => v ); // modals

		if( props.firstLoad === state.loaded ) {
			newState.loaded = true;
		}
		if( anyOverflowOpen !== state.overflowOpen ) {
			newState.overflowOpen = anyOverflowOpen;
		}
		if( anyModalOpen !== state.modalOpen ) {
			newState.modalOpen = anyModalOpen;
		}
		if( props.announcementsOpen !== state.announcementsOpen ) {
			newState.announcementsOpen = props.announcementsOpen;
		}

		return Object.keys( newState ).length ? newState : null;
	}

	componentDidMount() {
		const { initialRoute, initialId, secondaryId, initialPage, isVS } = this.props;
		// check privacy settings
		this.props.dispatch( checkGDPRStatus() );

		// Get userInfo as soon as possible
		this.props.dispatch( receiveCurrentUser( this.props.userInfo ) );
		if( this.props.userInfo && this.props.userInfo.id ) {
			this.props.dispatch( checkAdsPermissions( 0 ) );
		}

		//handling webkit/flex styles for older ios devices
		topifyConsole.info( 'deviceInfo:', topifyDevice );
		document.body.classList.add( devicePlatform(), topifyDevice.browser.engine );

		let landingPagePath = this.props.dispatch( getLandingPage() );

		// Cleaning url if needed
		if( /\?.*(rtemv|nocache|tfr|msgtype|msg)/i.test( window.location.search ) ) {
			let locationSearch = queryString.parse( location.search );
			// save msg if present
			this.initialMsg = {
				type: locationSearch.msgtype || 'warning',
				message: locationSearch.msg
			};

			// remove what we don't want
			delete locationSearch.msgtype;
			delete locationSearch.msg;
			delete locationSearch.rtemv;
			delete locationSearch.nocache;
			delete locationSearch.trf;

			let search = queryString.stringify( locationSearch );

			history.replaceState( null, '', location.origin + location.pathname + ( search ? '?' : '' ) + search + location.hash );

		} else if( /\?.*(msgtype|msg)/i.test( this.props.location.search ) ) {
			let locationSearch = queryString.parse( this.props.location.search );
			// save msg if present
			this.initialMsg = {
				type: locationSearch.msgtype || 'warning',
				message: locationSearch.msg
			};
			delete locationSearch.msgtype;
			delete locationSearch.msg;
			this.props.history.replace( { search: queryString.stringify( locationSearch ) } );
		}

		if( this.props.router.location.pathname === '/' ) {
			// if we have an initial route from the forum but we don't come from any other place
			if( initialRoute ) {
				if( initialRoute === 'portal' && parseInt( isVS ) !== 1 ) { // we only allow portal for VS sites
					this.props.dispatch( clearFirstLoad() );
				} else {
					let path = {
						pathname: '/' + initialRoute + "/" + initialId
					};
					// if we have a secondary Id take it
					if( secondaryId ) {
						path.search = queryString.stringify( { postid: secondaryId } );

					} else if( initialPage ) { // or if we have a page
						path.search = queryString.stringify( { page: initialPage } );
					}

					this.skipHideModals = true; // to avoid hiding modals on first load

					this.props.history.push( path );
				}

			} else if( landingPagePath !== this.props.location.pathname ) {
				// Go to the landingPage from UserSettings
				this.props.history.push( landingPagePath );
			}
		}

		// If we had an initial message on the query string to show, do it
		if( this.initialMsg.message ) {
			displayNotification( this.initialMsg.type, this.initialMsg.message, { timeout: 2000 } );
		}

		// If we have a message/alert from the plugin show it
		let { type, message } = this.props.initialMsg;
		if( message ) {
			displayNotification( ( type || 'info' ), message, { timeout: 10000 } );
		}

		if( this.props.userInfo && Object.keys( this.props.userInfo.is_banned ).length > 0 ) {
			this.skipHideModals = true; // to avoid hiding modals
			this.props.history.push( pathToForumsIndex() );
		}

		if( this.props.firstLoad ) {
			this.props.dispatch( clearFirstLoad() );
			this.initServiceWorker();
		}

		window.addEventListener( 'online', this.updateOnlineStatus );
		window.addEventListener( 'offline', this.updateOnlineStatus );
		window.addEventListener( 'popstate', this.resetOverWindows );

		window.addEventListener( 'beforeinstallprompt', e => {
			// Prevent Chrome 67 and earlier from automatically showing the prompt
			e.preventDefault();
			// Stash the event so it can be triggered later.
			this.deferredPrompt = e;
			this.setState( { deferredPrompt: true } );
		}, { once: true } );

		// pre-init ads
		this.props.dispatch( preInitAds() );
	}

	componentWillUnmount() {
		window.removeEventListener( 'popstate', this.resetOverWindows );
		window.removeEventListener( 'online', this.updateOnlineStatus );
		window.removeEventListener( 'offline', this.updateOnlineStatus );
	}

	shouldComponentUpdate( nextProps, nextState ) {
		return (
			nextState.isOnline !== this.state.isOnline ||
			nextState.loaded !== this.state.loaded ||
			nextState.overflowOpen !== this.state.overflowOpen ||
			nextState.modalOpen !== this.state.modalOpen ||
			nextState.announcementsOpen !== this.state.announcementsOpen ||
			nextProps.infiniteScrollPage !== this.props.infiniteScrollPage ||
			nextProps.location.pathname !== this.props.location.pathname ||
			nextProps.ads.showInterstitial !== this.props.ads.showInterstitial ||
			nextProps.userSettings.styleTheme !== this.props.userSettings.styleTheme ||
			nextProps.userSettings.fontSize !== this.props.userSettings.fontSize ||
			nextProps.privacySettings.cookiesOk !== this.props.privacySettings.cookiesOk ||
			nextProps.privacySettings.trackUserOk !== this.props.privacySettings.trackUserOk ||
			nextProps.isLoggedIn !== this.props.isLoggedIn
		);
	}

	componentDidUpdate( prevProps ) {
		// no-scroll: Prevents page scrolling while modal is open
		// with-overlay: blurs the page while the overlay is open
		let bodyClassesAdd = [], bodyClassesRemove = [];

		if( this.state.announcementsOpen || this.state.modalOpen || this.state.overflowOpen) {
			bodyClassesAdd.push( 'no-scroll' );
		} else {
			bodyClassesRemove.push( 'no-scroll' );
		}
		if( this.state.overflowOpen || this.state.modalOpen ) {
			bodyClassesAdd.push( 'with-overlay' );
		} else {
			bodyClassesRemove.push( 'with-overlay' );
		}
		if( this.state.modalOpen ) {
			bodyClassesAdd.push( 'with-overlay-header' );
		} else {
			bodyClassesRemove.push( 'with-overlay-header' );
		}

		// Adding classes
		document.body.classList.add( ...bodyClassesAdd );
		// Safely remove classes
		document.body.classList.remove( ...bodyClassesRemove );

		// What's the consent status? need to load ads or refresh?
		if(
			this.props.privacySettings.GDPR
			&& (
				prevProps.privacySettings.cookiesOk !== this.props.privacySettings.cookiesOk
				|| prevProps.privacySettings.trackUserOk !== this.props.privacySettings.trackUserOk
			)
		) {
			if(
				( !prevProps.privacySettings.cookiesOk && this.props.privacySettings.cookiesOk )
				|| ( !prevProps.privacySettings.trackUserOk && this.props.privacySettings.trackUserOk )
			) { // If we've got the consent init Ad Libraries
				this.props.dispatch( initAdLibraries() );

				// trigger Taboola
				this.props.dispatch( refreshTaboola( 'taboola' ) );
				this.props.dispatch( refreshTaboola( 'recirculation' ) );

			} else { // the user has revoked the consent, refresh to clean ads
				window.location.reload();
			}

		} else if( !this.props.privacySettings.GDPR && prevProps.firstLoad !== this.props.firstLoad ) {
			// initAds
			this.props.dispatch( initAdLibraries() );
		}

		if( prevProps.firstLoad !== this.props.firstLoad ) {
			// Tell the sw to refresh cache
			try {
				navigator.serviceWorker.controller.postMessage( {
					topify: true,
					process: 'checkPreCache'
				} );
				topifyConsole.log( 'SW: checkPreCache' );
			} catch( e ) { /* ignore */ }
		}

		// Track page view if it's the case
		if(
			prevProps.ads.analytics !== this.props.ads.analytics &&
			prevProps.router.location.pathname === this.props.router.location.pathname &&
			prevProps.infiniteScrollPage !== this.props.infiniteScrollPage
		) {
			// trigger ad updates
			this.props.dispatch( onPageView() );
		}
	}

	checkOldPhone = () => {
		//handling webkit/flex styles for older ios devices
		return ( topifyDevice.any &&
			(
				( topifyDevice.os.platform.ios && compareVersions( topifyDevice.os.version, '9' ) < 0 ) ||
				(
					topifyDevice.os.platform.android &&
					(
						( topifyDevice.browser.engine === 'Safari' && compareVersions( topifyDevice.browser.version, 534 ) <= 0 ) ||
						( topifyDevice.browser.engine === 'Chrome' && compareVersions( topifyDevice.browser.version, 37 ) <= 0 )
					)
				)
			)
		);
	};

	initServiceWorker = () => {
		if( window.location.protocol === 'https:' && 'serviceWorker' in navigator ) {
			// Override the default scope of '/' with location.origin, so that the registration applies
			// to the root and everything underneath it.
			let v = TOPIFY_VERSION.split( /[\-+]/ );
			navigator.serviceWorker.register( this.props.ff_url.replace( 'ff.php', `sw.php?v=${ v[ 0 ] || '' }` ), { scope: window.location.origin } ).then( registration => {
				// At this point, registration has taken place.
				// The service worker will not handle requests until this page and any
				// other instances of this page (in other tabs, etc.) have been
				// closed/reloaded.
				let serviceWorker;
				if( registration.installing ) {
					serviceWorker = registration.installing;
					topifyConsole.log( 'SW: installing' );
				} else if( registration.waiting ) {
					serviceWorker = registration.waiting;
					topifyConsole.log( 'SW: waiting' );
				} else if( registration.active ) {
					serviceWorker = registration.active;
					topifyConsole.log( 'SW: active' );
				}
				if( serviceWorker ) {
					let showStateChange = e => topifyConsole.log( 'SW: ', e.target.state );
					serviceWorker.addEventListener( 'statechange', showStateChange );
				}

			} ).catch( error => {
				// Something went wrong during registration. The sw.js file
				// might be unavailable or contain a syntax error.
				topifyConsole.error( 'SW: register failed', error );
			} );

		} else {
			// The current browser doesn't support service workers.
			topifyConsole.warn( window.location.protocol === 'https:' ? 'SW NOT SUPPORTED' : 'SW NOT ALLOWED - unsecured origin' );
		}
	};

	updateOnlineStatus = e => {
		const isOnline = navigator.onLine,
			type = isOnline ? 'info' : 'error',
			msg = isOnline ? "<p>You are back online</p>" : "<p><b>Internet connection lost!</b></p><p style='font-size: 0.8em'>You will have limited access to cached content until connection is restored.</p>",
			conf = isOnline ? { timeout: 1000, force: true } : { timeout: 3000, force: true };

		if( isOnline ) {
			this.setState( { isOnline: 'online' } );
			this.props.dispatch( retrievableContent( null ) );
		} else {
			this.setState( { isOnline: 'offline' } );
		}
		this.props.dispatch( onlineStatus( navigator.onLine ) );

		displayNotification( type, msg, conf );
	};

	resetOverWindows = ( e ) => {
		if( this.skipHideModals ) {
			e.preventDefault();
			this.skipHideModals = false;
			return true;
		}

		if( this.props.ui.modals.confirmModalOpened ) {
			if( e.state && e.state.type === 'confirmModal' ) {
				topifyConsole.info( 'cleaning history ', e.state );
				this.props.history.goBack();
			}

			this.props.dispatch( hideConfirmModal() );
			return true;
		}

		// In some cases we have more than 1 extra entry in history, let's clean them up
		if( e.state && e.state.topifyExtra ) {
			topifyConsole.info( 'cleaning history ', e.state );
			this.props.history.goBack();
		}

		// Close any modal open
		if(
			this.props.ui.overflows.headerOverflowOpened
			|| this.props.ui.overflows.searchBarOpened
			|| this.props.ui.overflows.sidebarOpened
			|| this.props.ui.overflows.shareMenuOpened
		) {
			this.props.dispatch( resetOverlay() );
		}
		if( this.props.ui.modals.userModalOpened ) {
			this.props.dispatch( hideUserModal() );
		}
		if( this.props.ui.modals.loginModalOpened ) {
			this.props.dispatch( hideLoginModal() );
		}
		if( this.props.ui.modals.replyModalOpened ) {
			this.props.dispatch( hideReplyModal() );
		}

		return true;
	};

	interstitialAd() {
		if( this.props.ads.showInterstitial ) {
			return <AdComponent key={ 'Interstitial_Ad' } adType={ 4 } adName="Interstitial_Ad"/>;
		} else {
			return null;
		}
	}

	cleanDeferredPrompt = () => {
		// cleaning
		this.deferredPrompt = null;
		this.setState( { deferredPrompt: false } );
	};

	render() {
		let style = this.props.userSettings.styleTheme ? ` style-${ this.props.userSettings.styleTheme }` : ' style-light',
			fontSize = this.props.userSettings.fontSize ? ` fontSize-${ this.props.userSettings.fontSize }` : ` fontSize-normal`,
			oldPhones = this.isOldPhone ? ' old_phones' : '',
			appClassNames = `${ oldPhones }${ style }${ fontSize } ${ this.state.isOnline }`;

		const replyModal = this.props.isLoggedIn ? <LoadableReplyModal/> : null;

		/**
		 * This is spacing between notifications.
		 * Note that since we reduce the padding of the alert (page.css -> .s-alert-wrapper)
		 * we need to adapt this spacing: ( ( <our padding top> - 22 ) * 2 ) + <the space we want>
		 * this way we have => ( ( 10 - 22 ) * 2 ) + 2) = -22
		 */
		const alertSpacing = -22;

		// We show a loader on first render
		// until we have all pre-process ready and potential redirection done
		let body = this.state.loaded !== true ?
			<LoaderCss style={{ marginTop: '30%' }}/> :
			<React.Fragment>
				<LoadableSearchBar />
				<HeaderOverflow isLoggedIn={ this.props.isLoggedIn } deferredPrompt={ this.deferredPrompt } cleanPrompt={ this.cleanDeferredPrompt } />
				<LoadableShareMenu />
				<section id="page">
					<Notices />
					<Routes />
					<div className="clearBottom" />
				</section>
				<AdComponent key={'Fixed_Bottom_Ad'} adType={ 3 } className="bottom-fixed-ad" adName="Fixed_Bottom_Leaderboard" />
				<LoadableSidebar />
				{/* Modal components */}
				<LoadableLoginModal />
				<LoadableConfirmModal />
				{replyModal}
				<UserModal isLoggedIn={ this.props.isLoggedIn }/>
				 {/* Overlay */}
				<Overlay />
				{this.interstitialAd()}
			</React.Fragment>;

		// TODO: <React.StrictMode> doesn't affect prod build and it will inform on console about potential issues. We can remove it after React 16.3 migration is complete
		return (
			<div id="app" className={ appClassNames.trim() }>
				{/*<React.StrictMode>*/}
					<Alert
						stack={{ limit: 3, spacing: alertSpacing }}
						timeout={4000}
						effect={this.isOldPhone ? '' : 'stackslide'}
						position={'top'}
						html={true}
						offset={2}
						contentTemplate={TopifyAlertTemplate}
					/>
					<Header isLoggedIn={ this.props.isLoggedIn } />
					{body}
				{/*</React.StrictMode>*/}
			</div>
		);
	}
}

PageComponent.propTypes = {
	initialRoute: PropTypes.string,
	initialId: PropTypes.string,
	secondaryId: PropTypes.string
};

export default withRouter( connect( pageSelectors )( PageComponent ) );