import {
	THREAD_FETCH,
	THREAD_SUCCESS,
	THREAD_FAILURE,
	THREAD_RESET,
	THREAD_REQUEST_EPIC,
	SET_THREAD,
	POST_CONTENTUPDATE
} from '../actions/ThreadviewActions';

import { THREAD_FOLLOWING } from '../actions/ForumviewActions';
import { LOADING_BEHAVIOR } from '../actions/InfiniteScrollActions';
import { isCachedChangedFor } from '../actions/CacheActions';

import merge from 'lodash/merge';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import { threadFollowing } from "Common/reducers/followingReducerHelper";

const initialState = {
	isFetching: false,
	isBgFetching: false,
	cacheDate: null,
	loaded: false,
	lastError: '',
	entities: {},
	result: {
		posts: [],
		topics: [],
		users: [],
		polls: [],
		pollOptions: [],
		poleVotes: []
	},
	pages: [],
	threadId: null,
	postid: null,
};

export default function threadview( prevState = initialState, action ) {
	switch( action.type ) {
		case THREAD_FETCH:
			return Object.assign( {},
				prevState,
				{
					isFetching: true,
					// Only set loaded to false if we are doing a full refresh
					loaded: !(action.thread.loadingBehavior === LOADING_BEHAVIOR.REFRESH)
				} );

		case THREAD_SUCCESS:
			let pageId = action.data.page === null ? 1 : parseInt( action.data.page );

			if( prevState.threadId !== action.thread.id || action.thread.loadingBehavior === LOADING_BEHAVIOR.REFRESH ) {
				return merge(
					{},
					action.data,
					{
						isFetching: false,
						isBgFetching: false,
						cacheDate: null,
						lastError: '',
						cacheChanged: isCachedChangedFor( {
							prevResult: prevState.result.posts,
							prevPage: prevState.page,
							currResult: action.data.result.posts,
							currPage: action.data.page
						} ),
						loaded: true,
						threadId: action.thread.id,
						pages: [ parseInt( action.data.page ) ]
					}
				);

			} else {
				let postsResult, usersResult, pages;

				if( action.thread.loadingBehavior === LOADING_BEHAVIOR.PREPEND ) {
					postsResult = uniq( [ ...action.data.result.posts, ...prevState.result.posts ] );
					usersResult = union( action.data.result.users, prevState.result.users );
					pages = uniq( [ pageId, ...prevState.pages ] );
				} else if( action.thread.loadingBehavior === LOADING_BEHAVIOR.APPEND ) {
					postsResult = uniq( [ ...prevState.result.posts, ...action.data.result.posts ] );
					usersResult = union( prevState.result.users, action.data.result.users );
					pages = uniq( [ ...prevState.pages, pageId ] );
				}

				return merge(
					{},
					prevState,
					action.data,
					{
						isFetching: false,
						isBgFetching: false,
						cacheDate: null,
						lastError: '',
						cacheChanged: isCachedChangedFor( {
							prevResult: prevState.result.posts,
							prevPage: prevState.page,
							currResult: postsResult,
							currPage: action.data.page
						} ),
						loaded: true,
					},
					{
						pages: pages,
						result: {
							posts: postsResult,
							users: usersResult
						}
					}
				);
			}

		case THREAD_FAILURE:
			return Object.assign( {}, prevState, {
				lastError: action.error,
				isFetching: false
			} );

		case THREAD_RESET:
			return initialState;

		case THREAD_FOLLOWING:
			return threadFollowing( prevState, action );

		case SET_THREAD:
			return {
				...action.state,
				cacheDate: action.date,
				cacheChanged: false,
			};

		case THREAD_REQUEST_EPIC:
			return {
				...prevState,
				isBgFetching: true
			};

		case POST_CONTENTUPDATE:
			let newPost = {
					dateline: action.data.entities.posts[ action.postId ].dateline,
					edited: action.data.entities.posts[ action.postId ].edited,
					message: action.data.entities.posts[ action.postId ].message,
					preview: action.data.entities.posts[ action.postId ].preview,
					attachments: action.data.entities.posts[ action.postId ].attachments,
					user_online: action.data.entities.posts[ action.postId ].user_online,
					unread: action.data.entities.posts[ action.postId ].unread,
					isDeleted: action.data.entities.posts[ action.postId ].isDeleted,
					isModerated: action.data.entities.posts[ action.postId ].isModerated,
					canEdit: action.data.entities.posts[ action.postId ].canEdit
				},
				prevData = {
					entities: { ...prevState.entities },
					result: { ...prevState.result }
				};

			// Update some fields of the post
			prevData.entities.posts[ action.postId ] = {
				...prevData.entities.posts[ action.postId ],
				...newPost
			};

			//Some other data might have changed

			// Clearing old attachments
			Object.values( prevData.entities.attachments || {} ).filter( at => at.post === action.postId ).forEach( attach => delete prevData.entities.attachments[ attach.id ] );

			// Did we add attachments?
			Object.keys( action.data.entities.attachments || {} ).map( at => {
				prevData.entities.attachments = prevData.entities.attachments || {};
				prevData.entities.attachments[ at ] = action.data.entities.attachments[ at ];
				prevData.result.attachments.push( at );
			} );

			// Update users, just in case
			Object.keys( action.data.entities.users || {} ).map( at => {
				prevData.entities.users[ at ] = action.data.entities.users[ at ];
				prevData.result.users.push( at );
			} );

			return {
				...prevState,
				entities: { ...prevState.entities, ...prevData.entities },
				result: { ...prevState.result, ...prevData.result },
				cacheDate: (new Date().getTime())
			};

		default:
			return prevState;
	}
}

