import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Thread from 'Components/threads/ThreadComponent';

import { markAsRead, markReadAnnouncement } from 'Actions/ThreadviewActions';

import forumViewSelector from 'Selectors/forumViewSelector';

import isEqual from 'lodash/isEqual';

class AnnouncementThreadListComponent extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			expanded: {}
		};
	}

	shouldComponentUpdate( nextProps, nextState ) {
		return (
			!isEqual( nextState.expanded, this.state.expanded ) ||
			!isEqual( nextProps.forumview.entities.announcements, this.props.forumview.entities.announcements )
		);
	}

	expandMessage = ( event ) => {
		let aId = event.currentTarget.dataset.threadid || null;

		if( isFinite( parseInt( aId ) ) ) {
			let collapsed = !this.state.expanded[ aId ];
			let nextExpanded = Object.assign( {}, this.state.expanded );

			// mark as read
			if( collapsed || collapsed === undefined ) {
				if( this.props.forumview.entities.announcements[ aId ].unread ) {
					this.props.dispatch( markReadAnnouncement( aId ) );
					if( this.props.ustate.id ) {
						this.props.dispatch( markAsRead( aId, 'announcement' ) );
					}
				}
				nextExpanded[ aId ] = true;
			} else {
				requestAnimationFrame( () => appContainer.scrollTop = 0 );
				delete nextExpanded[ aId ];
			}

			this.setState( { expanded: nextExpanded } );
		}
	};

	buildAnnouncement = ( key, topic, users ) => {
		let collapse = this.state.expanded[ topic.id ] ? '' : ' collapsed';
		return (
			<Thread
				key={ `announcements_${key}` }
				className={ `announcement${ collapse }` }
				thread={ topic }
				isSubscribed={ false }
				isSubscribable={ false }
				expandedContent={ topic.message }
				user={ users[topic.forumUser] }
				users={ users }
				dispatch={ this.props.dispatch }
				ustate={ this.props.ustate }
				isSelected={ false }
				expanded={ this.state.expanded[ topic.id ] } // note really used, just forces to upload
				onClick={ this.expandMessage }
			/>
		);
	};

	render() {
		const {entities: {users, announcements}, result} = this.props.forumview;

		if (result.announcements.length === 0) {
			return null
		}

		const announcementList = result.announcements.map( ( k, i ) => this.buildAnnouncement( i, announcements[ k ], users ) );

		return (
			<div id="announcements" className="threads announcement" >
				<div className="list-summary">
					Announcements ( { result.announcements.length } Topic{ result.announcements.length === 1 ? '' : 's' } )
				</div>
				<ul>
					{ announcementList }
				</ul>
			</div>
		);
	}
}

AnnouncementThreadListComponent.propTypes = {
	dispatch: PropTypes.func.isRequired,
	forumview: PropTypes.object.isRequired,
	ustate: PropTypes.object.isRequired
};

export default connect( forumViewSelector )( AnnouncementThreadListComponent );